import { Box } from '@mui/material'
import { graphql } from 'gatsby'
import React from 'react'
import BlogArticleCard from '../BlogArticleCard/BlogArticleCard'
import BlogHeader from '../BlogHeader/BlogHeader'
import Layout from '../Layout/Layout'
import SEO from '../SEO/SEO'
import rLogo from '../../images/icons/small_linear_r.svg'
import BlogPagination from '../BlogPagination/BlogPagination'
import useMediaQueries from '../../hooks/useMediaQueries'

type PageContext = {
  title: string
  slug: string | undefined
  description: string | undefined
  limit: number | undefined
  skip: number | undefined
  numPages: number | undefined
  currentPage: number | undefined
  // eslint-disable-next-line no-undef
  subCategories: Array<Queries.SanitySubCategoryEdge>
}

// eslint-disable-next-line no-undef
const BlogListSubCategory: React.FC<{
  // eslint-disable-next-line no-undef
  data: Queries.BlogListSubCategoryQuery
  pageContext: PageContext
}> = ({ data, pageContext }) => {
  const { isSmall } = useMediaQueries()
  return (
    <>
      <SEO
        title='Blog'
        description='A blog publishing helpful articles on frontend web development, Python, and other programming topics.'
      />
      <Layout image={data?.sanityHomePage?.logo?.asset || undefined}>
        <img
          alt='logo'
          src={rLogo}
          style={{
            width: '40vw',
            position: 'absolute',
            right: '0px',
            zIndex: -1,
            top: '175px',
            maxHeight: '50vh',
            opacity: isSmall ? 0.2 : 0.6,
          }}
        />
        {/* Add BlogHeader */}
        <Box mt={isSmall ? '175px' : '0px'}>
          <BlogHeader />
        </Box>
        <Box maxWidth='1000px' m='auto'>
          {data?.allSanityPost?.edges &&
            data?.allSanityPost?.edges.slice(0, 2).map(({ node }, i) => (
              <BlogArticleCard
                series={node?.series?.title}
                index={i}
                // eslint-disable-next-line no-underscore-dangle
                key={node._id}
                imageData={node.mainImage?.asset?.gatsbyImageData}
                title={node.title}
                excerpt={node.excerpt}
                route={`/blog/${node.slug?.current}`}
                dateString={node.publishedAt}
              />
            ))}
          {/* TODO: Add subscribe form */}
          {data?.allSanityPost?.edges &&
            data?.allSanityPost?.edges.slice(2).map(({ node }, i) => (
              <BlogArticleCard
                series={node?.series?.title}
                index={i}
                // eslint-disable-next-line no-underscore-dangle
                key={node._id}
                imageData={node.mainImage?.asset?.gatsbyImageData}
                title={node.title}
                excerpt={node.excerpt}
                route={`/blog/${node.slug?.current}`}
                dateString={node.publishedAt}
              />
            ))}
        </Box>
        <BlogPagination
          currentPage={pageContext.currentPage}
          numberOfPages={pageContext.numPages}
        />
      </Layout>
    </>
  )
}

export default BlogListSubCategory

export const query = graphql`
  query BlogListSubCategory($skip: Int!, $limit: Int!, $title: String) {
    site {
      siteMetadata {
        title
      }
    }
    sanityHomePage {
      logo {
        asset {
          gatsbyImageData(width: 306, height: 100, placeholder: BLURRED)
        }
      }
    }
    allSanityPost(
      filter: { subCategories: { elemMatch: { title: { eq: $title } } } }
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          _id
          series {
            title
            _id
          }
          mainImage {
            asset {
              gatsbyImageData(width: 485, placeholder: BLURRED)
            }
          }
          publishedAt(formatString: "MMMM d, YYYY")
          title
          slug {
            current
          }
          excerpt
        }
      }
    }
  }
`
